'use client';

import { useState } from 'react';
import { createClient } from '@supabase/supabase-js';

// Initialize Supabase client
const supabase = createClient(
  process.env.NEXT_PUBLIC_SUPABASE_URL!,
  process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!
);

export default function WaitlistForm() {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState('');

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsSubmitting(true);

    const { data, error } = await supabase
      .from('waiting_list')
      .insert([{ email, name }]);

    if (error) {
      setMessage(`Error: ${error.message}`);
    } else {
      setMessage(
        'Thank you for signing up! We will notify you when we launch.'
      );
    }

    setIsSubmitting(false);
    setEmail('');
    setName('');
  };

  return (
    <div className="max-w-md mx-auto mt-16 bg-gray-100 p-8 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold text-gray-900 mb-4">
        Join the Waitlist
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2" htmlFor="name">
            Name
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded text-gray-900"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded text-gray-900"
            required
          />
        </div>
        <button
          type="submit"
          className="w-full p-2 bg-gray-900 text-white font-bold rounded hover:bg-gray-700"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : 'Join the Waitlist'}
        </button>
      </form>
      {message && <p className="mt-4 text-center text-gray-700">{message}</p>}
    </div>
  );
}
